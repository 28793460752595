import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Hero from '../../components/Hero/Hero';
import styles from './customers.module.scss';
import FancySection from '../../components/FancySection/FancySection';
import PopupTrigger from '../../components/Popup/PopupTrigger';
import { newlines } from '../../utils';
import Layout from '../../components/Layout';

export const CustomerPageTemplate = ({
  seo,
  title,
  subtitle,
  showCaseStudy,
  caseStudyTitle,
  caseStudyLink,
  logos = [],
  features = [],
  quotes = [],
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={styles.customersPage}>
      <Hero>
        <div className={cx(styles.mainHero)}>
          <div className={cx('text', styles.header)}>
            <h1 className="display-2">{ newlines(title) }</h1>
            { subtitle && <h4>{subtitle }</h4> }
          </div>
          <PopupTrigger
            Trigger={(
              <div className={cx(styles.trigger)} style={{ cursor: 'pointer' }}>
                <img className={cx('desktop')} src='/images/uploads/video-player-slant.png' alt="Doordash loves Resource" />
                <img className={cx('mobile')} src='/images/uploads/video-player-flat.png' alt="Doordash loves Resource" />
              </div>
            )}
          >
            <iframe title="Doordash Loves Resource" width="100%" height="400" src="https://www.youtube.com/embed/XtoUDNUDFQU?rel=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
          </PopupTrigger>
        </div>
      </Hero>

      {showCaseStudy && (
      <div className={cx(styles.caseStudy)}>
        <a href={caseStudyLink}>
          <h2>
            {caseStudyTitle}
          </h2>
        </a>
      </div>
)}
        
      <section className={cx(styles.logos)}>
        <div className={cx('container')}>
          <div className={cx(styles.logosWrap, 'inline-container', 'ic-5')}>
            { logos.map(({logo: src}, i) => <div className={cx(styles.logo)} key={`lg${i}`}><img src={src} alt={src.split('/').pop()} /></div> )}
          </div>
        </div>
      </section>

      {features[0] && <FancySection {...features[0]} />}

      <section className={cx(styles.quotes)}>
        <div className={cx('container')}>
          <div className={cx(styles.quotesWrap, 'inline-container', 'ic-3')}>
            { quotes.slice(0, 3).map((q, i) => (
              <div className={cx(styles.quote)} key={`qu${i}`}>
                <div>
                  { q.logo && <img src={q.logo} alt={q.logo.split('/').pop()} /> }
                  <p className={cx(styles.quote)}>&ldquo;{q.quote}&rdquo;</p>
                </div>
                <p className={cx(styles.quoted)}>
                  <strong>
                    {q.quotedName}     
                  </strong>
                  <br />
                  <small>{q.quotedRole}</small>
                </p>
              </div>
          ))}
              
          </div>
        </div>
          
      </section>

      {features[1] && <FancySection {...features[1]} />}

      <section className={cx(styles.quotes)}>
        <div className={cx('container')}>
          <div className={cx(styles.quotesWrap, 'inline-container', 'ic-3')}>
            { quotes.slice(3).map((q, i) => (
              <div className={cx(styles.quote)} key={`qu${i + 3}`}>
                <div>
                  { q.logo && <img src={q.logo} alt={q.logo.split('/').pop()} /> }
                  <p className={cx(styles.quote)}>&ldquo;{q.quote}&rdquo;</p>
                </div>
                <p className={cx(styles.quoted)}>
                  <strong>{q.quotedName}</strong>
                  <br />
                  <small>{q.quotedRole}</small>
                </p>
              </div>
          ))}
          </div>
        </div>
      </section>
    </div>
    <script type="text/javascript">{`
        piAId = '824443';
        piCId = '1641';
        piHostname = 'pi.pardot.com';
        
        (function() {
          function async_load(){
            var s = document.createElement('script'); s.type = 'text/javascript';
            s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
            var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
          }
          if(window.attachEvent) { window.attachEvent('onload', async_load); }
          else { window.addEventListener('load', async_load, false); }
        })();
      `}
    </script>
  </Layout>
);

CustomerPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

CustomerPageTemplate.defaultProps = {
  subtitle: null,
};

const CustomerPage = ({ data, location }) => {
  const { markdownRemark: { frontmatter } } = data;
  return (
    <CustomerPageTemplate
      location={location}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...frontmatter}
    />
  );
};

CustomerPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default CustomerPage;

export const CustomerPageQuery = graphql`
  query CustomerPage {
    markdownRemark(frontmatter: { name: { eq: "customers" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        subtitle
        showCaseStudy
        caseStudyTitle
        caseStudyLink
        logos {
          logo
        }
        features {
          logo
          quote
          quotedName
          quotedRole
          quotedImg
          statValue
          statDescription
          img
          flip
          displayCaseStudy
          caseStudyTitle
          caseStudyLink
        }
        quotes {
          logo
          quote
          quotedName
          quotedRole
        }
      }
    }
  }
`;
